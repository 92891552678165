interface IItem {
  src: string;
  promise?: Promise<string>;
  resolve?(arg: string): void;
}

interface ILoad_script {
  (arg: string): any;
  scripts?: any[];
  index?: number;
  loading?: boolean;
  next?(): any;
}

export const load_script:ILoad_script = function(src:string) {
  // Initialize scripts queue
  if( load_script.scripts === undefined ) {
      load_script.scripts = [];
      load_script.index = -1;
      load_script.loading = false;
      load_script.next = function() {
          if( load_script.loading ) return;

          // Load the next queue item
          load_script.loading = true;
          if(load_script.scripts && load_script.index !== undefined) {
            const item = load_script.scripts[++load_script.index];
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = item.src;
            // When complete, start next item in queue and resolve this item's promise
            script.onload = () => {
                load_script.loading = false;
                if(load_script.index !== undefined &&
                  load_script.scripts &&
                  load_script.next &&
                  load_script.index < load_script.scripts.length - 1 ) load_script.next();
                item.resolve();
            };
            head.appendChild(script);
          }     
      };
  };

  // Adding a script to the queue
  if( src ) {
      // Check if already added
      for(let i=0; i < load_script.scripts.length; i++) {
          if( load_script.scripts[i].src === src ) return load_script.scripts[i].promise;
      }
      // Add to the queue
      var item:IItem = { src: src };
      item.promise = new Promise(resolve => {item.resolve = resolve;});
      load_script.scripts.push(item);
      if(load_script.next) load_script.next();
  }

  // Return the promise of the last queue item
  return load_script.scripts[ load_script.scripts.length - 1 ].promise;
};